// ACTiCLOUD customized components

// change column padding
$grid-gutter-width: 50px; //divided by 2
// define a more flexible grid
$grid-columns: 16;

// link formatting
//$link-hover-color: #96C0CE;
$base: #96C0CE;
$primary: darken($base, 8%);
$secondary: #69777F;

$secondary-lighter: #B7B7B7;
$secondary-darker: #545E66;
$primary-darker: #3E606F;
$secondary-lighter-alt: lighten($secondary-lighter, 8%);

$theme-colors:
(
  "primary-darker":        $primary-darker,
  "secondary-lighter":     $secondary-lighter,
  "secondary-lighter-alt": $secondary-lighter-alt,
  "secondary-darker":      $secondary-darker
);

$bg: lighten($secondary-lighter, 22%);
$link-hover-decoration: overline;

$tooltip-bg: darken($secondary-darker, 15%);

//$link-color: darken($link-hover-color, 15%); 

//$table-striped-order: even;

//$enable-gradients: true;
