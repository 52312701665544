// ACTiCLOUD customized components

// add some padding for columns on small screens
@include media-breakpoint-down(sm) {
  [class*="col-"]{
    margin-bottom: ($spacer * 2.5);
  }
}

// body font
body {
  font-family: 'Roboto', $font-family-base;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  text-transform: uppercase;
}

.no-upper {
  text-transform: none;
}

// bullet style
ul.acticloud {
  list-style-type: none;
  padding-left: 0;
}
ul.acticloud li:not(.col) {
  position: relative;
  padding: ($spacer / 2) 0 ($spacer / 2) $spacer;
}
ul.acticloud li:before {
  //content: '\276D';
  content: '';
  left: 0;
  //top: 5px;
  margin-left: (- $spacer / 2);
  position: absolute;
  color: #96C0CE;
  background: transparent url("images/bullet_triangle.png") no-repeat 0 0;
  background-size: cover;
  width: 16px;
  height: 24px;
  top: 0.45rem;
}
ul.acticloud li.col:before {
  margin: 0;
  top: 0.2rem;
}

ol.lower-alpha {
  list-style-type: lower-alpha;
  padding-left: 0;
  counter-reset: list;
}
ol.lower-alpha > li {
  list-style: none;
  padding: .5em 0 .5em;
}
ol.lower-alpha > li:before {
  content: counter(list, lower-alpha) ")  ";
  counter-increment: list;
}

// container separator
.container-sep {
  background-color: $bg;
  min-height: .5rem;
  margin-top: ($spacer * 3.2);
  margin-bottom: ($spacer * 3.2);
} 
.sep-thin {
  margin-top: 0;
  margin-bottom: 0;
}

// rounded box
.rounded-box {
  border: 1px dashed $primary;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}

// footer formatting
.footer {
  background-color: $secondary-darker;
  margin-top: ($spacer * 3);
  padding-top: ($spacer * 2);
  padding-bottom: $spacer;
  color: $secondary-lighter-alt;
}

// social buttons formatting
.social a {
  display: inline-flex;
  font-size: 1.5em;
  text-decoration: none;
  padding: .3em;
  margin-right: ($spacer * 0.3);
  border-radius: ($spacer * 0.25);
  vertical-align: top;

  &:last-child {
    margin-right: 0;
  }

  i {
    text-align: center;
    width: 1.25em;
    height: 1.25em;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      vertical-align: middle;
    }
  }
}
.footer .social {
  a {
    background-color: $secondary-darker;
    color: darken($secondary-lighter-alt, 4%);
    border: 1px solid darken($secondary-lighter-alt, 4%);
  
    @include hover-focus {
      color: lighten($secondary-lighter-alt, 18%);
      border-color: lighten($secondary-lighter-alt, 18%);
    }
    @include transition(ease .1s);
  }
}

// acticloud color classes
.i {
  color: $base;
}
.brand-primary {
  color: $primary;
}

.navbar-acticloud {
  font-family: $navbar-acticloud-font;
  font-weight: $navbar-acticloud-font-weight;
  background-color: $navbar-acticloud-bg;
  border-bottom: 1px solid #e7e7e7;
  text-transform: uppercase;

  align-items: stretch;

  .navbar-brand {
    color: $navbar-light-active-color;

    @include hover-focus {
      color: $navbar-light-active-color;
    }
    
    img {
      height: 40px;
    }

    display: flex;

    span {
      display: flex;
      align-items: end;
      padding-bottom: 0;
    }
  }

  .navbar-collapse {
    flex-grow: 0;
    height: 100%;
  }
  .navbar-collapse.show {
    .nav-item {
       margin-top: 0;
       padding-top: $navbar-acticloud-border-width;

       @include hover-focus {
         padding-top: 0;
       }
    }
    
    .nav-item.show,
    .nav-item.active {
      padding-top: 0;
    }
  }

  .navbar-nav {
    height: 100%;
    .nav-item {
      display: flex;
      align-items: flex-end;
      margin-top: (- $navbar-padding-y);
      padding-top: $navbar-padding-y;
      
      @include hover-focus {
        border-top: $navbar-acticloud-border-width solid $navbar-acticloud-link-hover-color;
        padding-top: $navbar-padding-y - $navbar-acticloud-border-width;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .nav-link {
      color: $navbar-light-color;
      
      @include hover-focus {
        color: $navbar-acticloud-link-hover-color;
      }
      
      padding-bottom: ($navbar-brand-padding-y + .06);
    }

    .dropdown-item {
      color: $navbar-light-color;

      &.active,
      &:active {
	background-color: lighten($primary, 34%);
      }
      @include hover-focus {
	background-color: lighten($primary, 34%);
      }
    }
    
    .nav-item.show,
    .nav-item.active {
      border-top: $navbar-acticloud-border-width solid $navbar-acticloud-link-hover-color;
      padding-top: $navbar-padding-y - $navbar-acticloud-border-width;
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-acticloud-link-hover-color;
    }
  }

  .navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-light-color;
    a {
      color: $navbar-light-active-color;

      @include hover-focus {
        color: $navbar-light-active-color;
      }
    }
  }
}
.navbar-acticloud-secondary {
  font-weight: 400;
  font-size: 85%;
  background-color: $primary-darker;
  color: $gray-500;
  border-bottom: none;
  text-transform: none;
  padding-top: 0;
  padding-bottom: 0;

  .navbar-text {
    color: $gray-500;
    padding-top: ($nav-link-padding-y * .3);
    padding-bottom: ($nav-link-padding-y *  .3);
  }

  a, .navbar-text a {
    font-weight: 700;
    color: inherit;
 
    @include hover-focus {
      color: $gray-300;
      text-decoration: none;
    }
  }
}

.table-acticloud {
  // custom table color
  &.table-hover {
    tbody tr {
      @include hover {
        background-color: theme-color-level($level:-9);
      }
    }
  }
}

.toggler {
  cursor: pointer;
}

.btn-table th,
.btn-table td { 
  vertical-align: middle;
}

.acticloud .carousel-control-prev,
.acticloud .carousel-control-next {
  width: auto;
  top: 42% !important;
  bottom: 42% !important;
  opacity: .8;

  @include hover-focus {
    color: $acticloud-carousel-control-color;
    text-decoration: none;
    outline: 0;
    opacity: .9;
  }
}

.acticloud .carousel-control-prev-icon,
.acticloud .carousel-control-next-icon { 
  width: 40px !important;
  height: 40px !important;
  background-color: rgba($white, 1) !important;
  background-size: 65% 65% !important;
}
.acticloud .carousel-control-prev-icon {
  box-shadow: 2px 1px 3px -1px rgba($black, .15);
  background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$acticloud-carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !important;
}
.acticloud .carousel-control-next-icon { 
  box-shadow: -2px 1px 3px -1px rgba($black, .15);
  background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$acticloud-carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !important;
}
