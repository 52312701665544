// ACTiCLOUD customized components

$headings-margin-bottom: $spacer;
// headings' font
$headings-font-family: 'Roboto Condensed', $font-family-base;
$headings-font-weight: 700;
$headings-color: #545E66;

// navbar
$navbar-acticloud-bg: #fff;
$navbar-acticloud-font: 'Roboto Condensed', $font-family-base;
$navbar-acticloud-font-weight: 700;
$navbar-acticloud-link-hover-color: $link-color;
$navbar-acticloud-link-active-color: $navbar-acticloud-link-hover-color;
$navbar-acticloud-border-width: ($spacer / 4);

// add more spacers
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 4),
    7: ($spacer * 5)
  ),
  $spacers
);

$acticloud-carousel-control-color: rgba(#000, .65); 
