.timeline-Header {
  display: none;
}

.timeline-Body {
  .timeline-Tweet-text {
    font-size: $font-size-sm !important;
	font-weight: normal !important;
  }
  .timeline-Tweet-media {
    display: none;
  }

  .TweetAuthor-link {
    color: $navbar-light-color !important;
  }
  a {
    color: $link-color !important;
	&:hover,
	&:focus {
      color: $link-hover-color !important;
      text-decoration: $link-hover-decoration !important;
	}
  }
}

.timeline-Footer {
  display: none;
}
